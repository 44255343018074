@import "@assets/variables";

.floating-contact {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    z-index: 3;
    transform: translateY(0);
    transition: 200ms;

    .avatar {
        position: relative;
        z-index: 1;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transform: translate(-1px, -1px);
            background-color: #FFF;
        }
    }

    .avatar-details-wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        opacity: 0;
        right: 0;
        z-index: 0;
        width: 25vw;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 10px -5px var(--secondary-color);
        transition: all 200ms ease-in-out;
        transform: scale(calc(5vw / 21vw));
        transform-origin: bottom right;
        border-bottom: 5.5vh solid var(--secondary-color);

        .avatar-details {
            display: flex;
            flex-direction: column;
            padding: 40px 30px 90px;
            opacity: 0;
            transition-delay: 0ms;
            transition: 200ms;

            .avatar-details-container {
                border-bottom: 1px solid rgba(83, 77, 103, 0.2);

                .avatar-details-row {
                    margin-bottom: 1rem;
                }
                
                &+.avatar-details-row {
                    padding-top: 1rem;
                }
            }            

            .avatar-details-row {
                display: flex;
                align-items: center;
                gap: 0.625rem;

                i[class*=icon] {
                    background-color: var(--primary-color);
                    padding: 0.61rem;
                    border-radius: 50%;
                    font-size: clamp(16px, 2.2vh, 24px);
                }

            }

            h6 {
                margin-bottom: 0.9375rem;
            }

            h3 {
                word-wrap: break-word;
                padding-bottom: 1.875rem;
                border-bottom: 1px solid rgba(83, 77, 103, 0.2);
                margin-bottom: 0.9375rem;
            }

            a,
            p {
                margin-bottom: 0.9375rem;

                &:last-child {
                    margin: 0;
                }
            }
        }

        .close {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            background-color: white;
            transform: translate(-50%, -50%);
            top: 0;
            width: 41px;
            height: 41px;
            border: 1.79px solid rgba(83, 77, 103, 1);
            border-radius: 50%;
            cursor: pointer;
            opacity: 0;
            transition: 200ms;
            transition-delay: 0ms;

            i {
                color: rgba(83, 77, 103);
                font-size: 1rem;
            }
        }

        &.show {
            opacity: 1;
            border-radius: 10px;
            transform: scale(1) translate(1vw, 1vh);

            .avatar-details {
                opacity: 1;
                transition-delay: 220ms;
            }

            .close {
                opacity: 1;
                transition-delay: 220ms;
            }
        }
    }

    &.is-top {
        transform: translateY(8vh);
    }
}

@media screen and (max-width: 1400px) {
    .floating-contact {
        .avatar-details-wrapper {
            width: 27vw;

            .close {
                width: 3.5vw;
                height: 3.5vw;

                i {
                    font-size: 1.1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .floating-contact {
        .avatar-details-wrapper {
            width: 32vw;

            .close {
                width: 5vw;
                height: 5vw;

                i {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: $tabletWidth) {
    .floating-contact {
        width: 60px;
        height: 60px;

        .avatar-details-wrapper {
            width: 85vw;
            max-width: 304px;

            .close {
                width: 6vw;
                height: 6vw;

                i {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: $mobileWidth) {
    .floating-contact {
        width: 80px;
        height: 80px;

        .avatar-details-wrapper {
            width: 85vw;

            .avatar-details {
                padding: 24px 24px 60px;

                >* {
                    margin-bottom: 2vw;
                }

                a {
                    font-size: 0.8rem;
                }

                .avatar-details-row {

                    i[class*=icon] {
                        padding: 0.282rem;
                        font-size: 12px;
                    }
                }
            }

            .close {
                width: 8vw;
                height: 8vw;

                i {
                    font-size: 0.82rem;
                }
            }
        }

        &.is-top {
            transform: translateY(7vh);
        }
    }
}