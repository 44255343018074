@font-face {
  font-family: 'wetu-font-icons';
  src: url('./wetu-font-icons.eot?gx0o30');
  src: url('./wetu-font-icons.eot?gx0o30#iefix') format('embedded-opentype'),
    url('./wetu-font-icons.ttf?gx0o30') format('truetype'),
    url('./wetu-font-icons.woff?gx0o30') format('woff'),
    url('./wetu-font-icons.svg?gx0o30#wetu-font-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'wetu-font-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-more-horizontal:before {
  content: "\e900";
}
.icon-more-vertical:before {
  content: "\e901";
}
.icon-motorbike:before {
  content: "\e902";
}
.icon-phone:before {
  content: "\e903";
}
.icon-pin-solid:before {
  content: "\e904";
}
.icon-plane:before {
  content: "\e905";
}
.icon-plane-north:before {
  content: "\e906";
}
.icon-plus:before {
  content: "\e907";
}
.icon-restaurant:before {
  content: "\e908";
}
.icon-rv:before {
  content: "\e909";
}
.icon-seaplane:before {
  content: "\e90a";
}
.icon-seaplane-north:before {
  content: "\e90b";
}
.icon-send:before {
  content: "\e90c";
}
.icon-speedboat:before {
  content: "\e90d";
}
.icon-train:before {
  content: "\e90e";
}
.icon-tram:before {
  content: "\e90f";
}
.icon-users:before {
  content: "\e910";
}
.icon-vehicle-4x4:before {
  content: "\e911";
}
.icon-vehicle-4x4-enclosed:before {
  content: "\e912";
}
.icon-walking:before {
  content: "\e913";
}
.icon-x:before {
  content: "\e914";
}
.icon-zap:before {
  content: "\e915";
}
.icon-zoom-in:before {
  content: "\e916";
}
.icon-activity-optional:before {
  content: "\e917";
}
.icon-activity-planned:before {
  content: "\e918";
}
.icon-activity-recommended:before {
  content: "\e919";
}
.icon-alert-circle:before {
  content: "\e91a";
}
.icon-arrow-down:before {
  content: "\e91b";
}
.icon-arrow-left:before {
  content: "\e91c";
}
.icon-arrow-right:before {
  content: "\e91d";
}
.icon-arrow-up:before {
  content: "\e91e";
}
.icon-bed:before {
  content: "\e91f";
}
.icon-bus:before {
  content: "\e920";
}
.icon-canoe:before {
  content: "\e921";
}
.icon-car:before {
  content: "\e922";
}
.icon-charter-flight:before {
  content: "\e923";
}
.icon-charter-flight-north:before {
  content: "\e924";
}
.icon-chevron-down:before {
  content: "\e925";
}
.icon-chevron-left:before {
  content: "\e926";
}
.icon-chevron-right:before {
  content: "\e927";
}
.icon-chevrons-up:before {
  content: "\e928";
}
.icon-chevron-up:before {
  content: "\e929";
}
.icon-crosshair:before {
  content: "\e92a";
}
.icon-cruise:before {
  content: "\e92b";
}
.icon-cycling:before {
  content: "\e92c";
}
.icon-day-tour:before {
  content: "\e92d";
}
.icon-ferry:before {
  content: "\e92e";
}
.icon-globe:before {
  content: "\e92f";
}
.icon-helicopter-flight:before {
  content: "\e930";
}
.icon-helicopter-flight-north:before {
  content: "\e931";
}
.icon-hike:before {
  content: "\e932";
}
.icon-horseback:before {
  content: "\e933";
}
.icon-houseboat:before {
  content: "\e934";
}
.icon-info:before {
  content: "\e935";
}
.icon-location:before {
  content: "\e936";
}
.icon-mail:before {
  content: "\e937";
}
.icon-map-pin:before {
  content: "\e938";
}
.icon-meet-and-greet:before {
  content: "\e939";
}
.icon-menu:before {
  content: "\e93a";
}
