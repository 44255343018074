@import "@assets/variables";

.swiper-container {
    height: 100%;
    width: 100%;
    position: relative;
    background: transparent;

    .has-info {
        height: 95% !important;
        position: absolute !important;
        top: 0;
        block-size: 95% !important;
    }
}

.slider {
    &.gallery {
        height: 100%;
        padding-top: 4vh;
    }

    .tooltip-content {
        opacity: 1;
        background: #534D67;
        max-width: 35vw;
        margin-top: 5px;
    }

    .media-info-bar {
        padding: 0.25em;
        position: absolute;
        bottom: 0;
        height: 5%;
        width: 100%;
        color: rgba(83, 77, 103, 0.7);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.6em;
        overflow: visible; // required for tooltip

        :last-child {
            margin-left: auto;
        }

        i {
            cursor: pointer;
        }

        .media-info-text {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-grow: 1;
            gap: 1rem;
            width: 90%;

            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        * {
            font-size: 0.85rem;
        }
    }

    .swiper-navigation-button {
        position: absolute;
        top: 50%;
        width: 13.28125vw;
        height: 13.28125vw;
        background-color: rgba(255, 255, 255, 0);
        display: flex;
        align-items: center;
        z-index: 2;
        cursor: pointer;
        -webkit-transition: 200ms;
        transition: 200ms;
        border-radius: 50%;
        justify-content: center;

        i {
            width: 4vh;
            height: 4vh;
            border-radius: 50%;
            background-color: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--secondary-color);
            font-size: clamp(16px, 2.2vh, 22px);
        }

        &.swiper-navigation-button-prev {
            transform: translate(-35%, -50%);
            left: 0;
        }

        &.swiper-navigation-button-next {
            transform: translate(35%, -50%);
            right: 0;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.3);
        }
    }

    .swiper-container:not(.mutiple-slides-per-view) {
        .swiper-slide {
            opacity: 0 !important;
            background-color: rgba(255, 255, 255, 1);
        }

        .swiper-lazy-preloader {
            -webkit-animation: none;
            animation: pulse 2s infinite linear !important;
        }
    }

    .swiper-slide {
        background-color: rgba(255, 255, 255, 1);
    }

    .swiper-slide-active {
        opacity: 1 !important;
    }

    .swiper-lazy-preloader {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 0;
        margin-top: 0;
        z-index: 10;
        transform-origin: 0;
        border: none;
        border-radius: 0;
        background: rgba(226, 223, 223, 0.4);
        animation: none!important;

        @keyframes pulse {
            0% {
                background-color: rgba(255, 255, 255, 1);
            }

            50% {
                background: rgba(226, 223, 223, 0.4);
            }

            100% {
                background-color: rgba(255, 255, 255, 1);
            }
        }

    }
}



@media screen and (max-width: $tabletWidth) {
    .slider {
        .swiper.slide {
            opacity: 1 !important;
        }
    }
}

@media screen and (max-width: $mobileWidth) {
    .swiper-container {
        background-color: white;
        height: auto !important;
        overflow: hidden;

        .has-info {
            height: auto !important;
            position: relative !important;
            overflow: hidden;
            top: 0;
            block-size: auto !important;

        }

        .slide-up-text {
            padding-inline: 0.5rem;
        }
    }

    .slider {

        &.gallery {
            padding-top: 0;
        }

        .media-info-bar {
            height: auto;
            position: relative;
            flex-direction: row-reverse;
            padding: 2.5vw 5vw 0;
            padding-top: 0;
            gap: 1em;

            i {
                font-size: 24px;
            }
        }

        .media-info-bar {
            .media-info-text {
                flex-direction: column;
                gap: 0.25em !important;

                :last-child {
                    margin-left: 0;
                }

                p {
                    white-space: wrap;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }

            &>* {
                margin-top: 0.5em;
            }
        }


    }
}