@import '@assets/variables';

.break-words {
    word-break: break-word;
}

.bold {
    font-weight: bold;
}

.bg-white {
    background-color: #fff;
}

.grey-text {
    color: rgba(83, 77, 103, 0.7);
}

.light-text {
    color: white;
}

.dark-text {
    color: #534D67;
}

.mb-\[1\.875rem\] {
    margin-bottom: 1.875rem;
}

.ml-auto {
    margin-left: auto;
}

.pr-\[20px\] {
    padding-right: 20px
}

.pr-\[10px\] {
    padding-right: 10px;
}

.pl-\[20px\] {
    padding-left: 10px;
}

.px-\[30px\]  {
    padding-left: 30px;
    padding-right: 30px;
}

@media screen and (max-width: $tabletWidth) {
    .text-md-center {
        text-align: center;
    }

    .w-md-100 {
        width: 100%;
    }

    .pr-md-\[10px\] {
        padding-right: 10px;
    }
    
    .pl-md-\[20px\] {
        padding-left: 20px;
    }
}

@media screen and (max-width: $mobileWidth) {
    .w-sm-100 {
        width: 100%;
    }
}