@import "@assets/variables";

.nav-bar {
    .nav-bar-menu {
        flex: 0 0 8vh;
        display: flex;
        list-style-type: none;
        gap: 30px;
        text-transform: uppercase;
        transform: translateY(calc(-100%));
        align-items: center;
        margin: 0;
        padding: 0 30px;
        z-index: 997;
        width: calc(100% - 200px);
        transition: 200ms;
        pointer-events: auto;

        li {
            display: flex;
            align-items: center;
            cursor: pointer;

            > h6 {
                color: #534D67;
                -webkit-transition: 200ms;
                transition: 200ms;
            }

            i {
                font-size: 24px;
                margin: 0 0 0 1rem;
                -webkit-transition: 200ms;
                transition: 200ms;

                &:before {
                    color: #534D67;
                }
            }
        }
    }

    &.transparent {
        .nav-bar-menu {
            li {
                > h6 {
                    color: #FFF;                    
                }

                i {
                    &:before {
                        color: #FFF;
                    }
                }
            }
        }
    }

    &.dropdown-open {
        .nav-bar-menu {
            li {
                i {
                    transform: rotate(-180deg)
                }
            }
        }
    }
}

@media screen and (max-width: $tabletWidth) {
    .nav-bar {
        .nav-bar-menu:not(.navbar-lite) {
            flex-direction: column;
            align-items: flex-start;
            background-color: #FFF;
            z-index: 996;
            -webkit-transition: 200ms;
            transition: 200ms;
            width: 100%;
            height: unset;
            opacity: 0;
            box-shadow: 0 3px 7px rgba(0,0,0,0.2);
            pointer-events: none;
            gap: 30px;
            padding: 0 5px;

            li {
                > h6 {
                    font-weight: 500;
                    padding: 0 20px;          
                    color: #534D67;
                    user-select: none;
                }

                &:first-child {
                    padding-top: 20px;
                }

                &:last-child {
                    padding-bottom: 15px;
                }
            }
        }

        &.transparent {
            .nav-bar-menu {
                li {
                    > h6 {
                        color: initial;                    
                    }
                    i {
                        &:before {
                            color: initial;
                        }
                    }
                }
            }
        }

        &.menu-open {
            .nav-bar-menu {
                opacity: 1;
                transform: translateY(-1px);
                transition-delay: 100ms;
                box-shadow: 0 3px 7px rgba(0,0,0,0.2);
                pointer-events: auto;

                li {
                    > h6 {
                        color: #534D67;
                    }
                    i {
                        &:before {
                            color: #534D67;
                        }
                    }
                }
            }
        }

        &.dropdown-open {
            .nav-bar-menu {
                box-shadow: none;
            }
        }
    }
}

@media screen and (max-width: $mobileWidth) {
    .nav-bar {
        .nav-bar-menu {
            opacity: 0;
            pointer-events: none;
        }
    }
}