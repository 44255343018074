@import '@assets/variables';
@import '@assets/utilities';

:root {
  --primary-color: #BDC3C7;
  --secondary-color: #BDC3C7;
  --accent-color: #95A5A6;
  --hyperlink-color: #95A5A6;

  --body-font: "Montserrat";
  --headings-font: "Garamond";
}

html, body {
  font-size: 14px;
  font-family: var(--body-font);
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

p,
h6,
h5,
h4,
h3,
h2,
h1 {
  font-weight: 400;
  margin: 0;
}

h5,
h4,
h3,
h2,
h1 {
  font-family: var(--headings-font);
}

p,
a,
h6,
button {
  font-family: var(--body-font);
}

p {
  font-size: 1rem;
  line-height: 150%;
}

a {
  font-size: 1rem;
  line-height: 150%;
  color: var(--hyperlink-color);
  text-decoration: var(--hyperlink-decoration);
}

p {
  &.xs {
    font-size: 0.875rem;
    opacity: 0.7;
  }
}

h6 {
  font-size: 1rem;
  line-height: 120%;
  text-transform: uppercase;
}

h5 {
  font-size: 1.33rem;
  line-height: 120%;

  &.h5-70 {
    opacity: 0.7;
  }
}

h4 {
  font-size: 1.56rem;
  line-height: 120%;
}

h3 {
  font-size: 2.4rem;
  line-height: 120%;
}

h2 {
  font-size: 3.15rem;
  line-height: 120%;
}

h1 {
  font-size: 4.2rem;
  line-height: 120%;
}

.app {
  display: flex;
  flex-direction: column;

  .spinner-wrap {
    height: 100vh;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  border-radius: 8px;
  padding: 1.7vh;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.5vh;
  cursor: pointer;
  background-color: var(--button-color);

  &:disabled {
    background-color: #D9D9D9;
    color: white;
    pointer-events: none;
  }

  &:hover {
    box-shadow: 0 0 5px 0 #4B4B4B4D;
  }

  &:active {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba($color: #000000, $alpha: 0.08);
      border-radius: 8px;
    }
  }

  &.round {
    border-radius: 50%;

    &:after {
      border-radius: 50%;
    }
  }
}

.floating-elements {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 10vh;
  right: 2vw;
  z-index: 999;

  .floating-contact {
    bottom: 3vh;
  }
}


@media screen and (max-width: $tabletWidth) {
  
  html, body {
    font-size: 16px;
  }

  h1 {
    font-size: 3.05rem;
  }

  h2 {
    font-size: 2.18rem;
  }

  h3 {
    font-size: 1.625rem;
  }

  h4 {
    font-size: 1.31rem;
  }

  h5 {
    font-size: 1.125rem;

    &.h5-70 {
      font-size: 1.33rem;
    }
  }

  p,
  a,
  h6,
  li {
    font-size: 0.875rem;
  }

  p {
    &.xs {
      font-size: 0.75rem;
    }
  }

  .floating-elements {
    right: 5vw;

    .floating-contact {
      bottom: 2vh;
    }
  }
}