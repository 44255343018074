.spinner-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeedee;

  .spinner {
    height: 50px;
    width: 50px;
    border: 3px solid #534D67;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }

  .lottie-spinner {
    width: '100%';

    // Default style, applies when the screen is extra-large (greater than 1200px)
    max-width: 220px;

    // Large devices (desktops less than 1200px)
    @media (max-width: 1199.98px) {
      max-width: 180px !important;
    }

    // Medium devices (tablets less than 992px)
    @media (max-width: 991.98px) {
      max-width: 160px !important;
    }

    // Small devices (landscape phones less than 768px)
    @media (max-width: 767.98px) {
      max-width: 120px !important;
    }

    // Extra small devices (portrait phones less than 576px)
    @media (max-width: 575.98px) {
      max-width: 120px !important;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

button.btn {
  .hide {
    visibility: hidden;
  }

  .loading {
    position: absolute;
  }

  &.light-text {
    .spinner {
      border: 2px solid #f3f3f3;
      border-top: 2px solid #333;
    }
  }

  &.dark-text {
    .spinner {
      border: 2px solid #534d67;
      border-top: 2px solid #f3f3f3;
    }
  }

  .spinner-wrap {
    height: auto;
    width: auto;
    background: transparent;

    .spinner {
      border-radius: 50%;
      width: 18px;
      height: 18px;
      -webkit-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
      margin: 0;
    }
  }
}
