@import "@assets/variables";

.nav-bar {
    .co-branding-logo {
        --logo_size: 75px;

        position: absolute;
        left: 30px;
        transition: 200ms;
        z-index: 995;
        top: calc(8vh + var(--logo_size) + 30px);
        transform: translateY(0px);
        opacity: 1;
        
        &.small {
            --logo_size: 75px;
        }
        
        &.medium {
            --logo_size: 100px;
        }
        
        &.large {
            --logo_size: 150px;
        }

        &.hidden {
            transform: translateY(calc(var(--logo_size) * -1));
            opacity: 0;
        }
        
        img {
            object-fit: contain;
            max-inline-size: var(--logo_size);
            max-block-size: var(--logo_size);
        }
    }
}

@media screen and (max-width: $desktopWidth) {
    .nav-bar {
        .co-branding-logo {
            --logo_size: 100px !important;
        }
    }
}

@media screen and (max-width: $tabletWidth) {
    .nav-bar {
        .co-branding-logo {
            --logo_size: 75px !important;

            top: 0 !important;
            right: 10px;
            left: unset;
            transform: none !important;
            z-index: 998;
            height: 75px;
            display: flex;
            align-items: center;

            img {
                box-shadow: none;
            }
        }
    }
}