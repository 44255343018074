@import "@assets/variables";

.back-to-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    background-color: var(--secondary-color);
    cursor: pointer;
    opacity: 0;
    transition: 200ms;
    transition-delay: 0ms;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);

    i {
        font-size: 1.5rem;
    }

    &.show {
        transition-delay: 100ms;
        opacity: 1;
    }
}

@media screen and (max-width: 1400px) {
    .back-to-top {
        width: 55px;
        height: 55px;

        i {
            font-size: 1.4rem;
        }
    }
}

@media screen and (max-width: 1200px) {
    .back-to-top {
        width: 50px;
        height: 50px;

        i {
            font-size: 1.3rem;
        }
    }
}

@media screen and (max-width: 992px) {
    .back-to-top {
        width: 45px;
        height: 45px;

        i {
            font-size: 1.2rem;
        }
    }
}

@media screen and (max-width: $tabletWidth) {
    .back-to-top {
        width: 40px;
        height: 40px;

        i {
            font-size: 1rem;
        }
    }
}