@import "@assets/variables";

.nav-bar-dropdown {
    background-color: #FFF;
    border-radius: 8px;
    opacity: 0;
    pointer-events: none;
    transition: 200ms;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
    overflow: auto;
    width: 100%;
    max-width: 320px;
    margin: -9vh 0 5vh 230px;
    z-index: 998;

    .nav-bar-dropdown-leg {
        cursor: default;

        .days {
            font-weight: 500;
            background-color: rgba(2, 71, 81, 0.05);
            padding: 1.3vh 1.2vw;
            color: #534D67;
        }

        .nav-bar-dropdown-leg-section {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 1.5vw;
                top: 2.5vh;
                width: 1px;
                height: 100%;
                background-color: var(--secondary-color);
            }

            &:last-child {
                &::before {
                    content: none;
                }
            }

            .circle {
                position: absolute;
                left: 0;
                border: 1px solid var(--secondary-color);
                width: 24px;
                height: 24px;
                border-radius: 50%;
                transform: translateX(-50%);
                z-index: 1;
                background-color: #FFF;
            }

            .nav-bar-dropdown-leg-section-heading {
                display: flex;
                align-items: center;
                padding: 1.5vh 0 0.7vh 1.5vw;
                margin-left: 1.5vw;
                cursor: pointer;
                position: relative;

                p {
                    display: flex;
                    align-items: center;
                    text-transform: none;
                    opacity: 0.6;
                    height: 24px;
                    font-size: clamp(10px, 1.2vh, 12px);
                }

            }

            .nav-bar-dropdown-leg-section-item {
                display: flex;
                align-items: center;
                margin: 0 0 0.6vh 1.5vw;
                padding: 0 1vw 0 1.5vw;
                cursor: pointer;

                p {
                    line-height: 2vh;
                }

                &.alternate-accommodation {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    p {
                        margin-bottom: 1vh;
                        text-transform: none;
                        font-size: clamp(10px, 1.2vh, 12px);

                        &:first-child {
                            margin-top: 1.5vh;
                        }
                    }
                }
            }

            &:nth-child(2) {
                .nav-bar-dropdown-leg-section-heading {
                    border-left: none;
                }
            }

            &:last-child {
                .nav-bar-dropdown-leg-section-item {
                    padding-bottom: 0;
                    margin-bottom: 2vh;
                }
            }

            &.active,
            &:hover {
                .circle {
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: var(--secondary-color);
                    }
                }
            }
        }
    }

    &.open {
        opacity: 1;
        pointer-events: auto;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--secondary-color);
        border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
}

@media screen and (max-width: $tabletWidth) {
    .nav-bar-dropdown {
        border-radius: 0;
        box-shadow: none;
        margin: -1px 0 0 0;
        max-width: unset;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        transform: translateY(calc(-100% - 30vh));
        z-index: 995;
        opacity: 1;

        .nav-bar-dropdown-leg {
            .days {
                padding: 1.3vh 5vw;
            }

            .nav-bar-dropdown-leg-section {
                &:before {
                    left: 6vw;
                }

                .nav-bar-dropdown-leg-section-heading {
                    margin-left: 6vw;
                    padding-left: 6vw;
                }

                .nav-bar-dropdown-leg-section-item {
                    margin-left: 6vw;
                    padding: 0 1vw 0.5vh 6vw;
                    cursor: pointer;
                }
            }
        }

        &.open {
            transform: translateY(0);
            transition: 300ms;
        }
    }
}