@import "@assets/variables";

.nav-bar-top {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 998;
    pointer-events: auto;
    -webkit-transition: 200ms;
    transition: 200ms;
    overflow: hidden;

    img {
        object-fit: contain;
        vertical-align: middle;
    }

    &.small {
        height: 75px;
        max-block-size: 75px;
    }

    &.medium {
        height: 100px;
        max-block-size: 100px;
    }

    &.large {
        height: 150px;
        max-block-size: 150px;
    }
}

@media screen and (max-width: $desktopWidth) {
    .nav-bar-top {
        height: 100px !important;

        img {
            width: 200px !important;
            height: 100px !important;
            max-block-size: 100px !important;
        }
    }
}

@media screen and (max-width: $tabletWidth) {
    .nav-bar-top {
        padding: 0 20px;
        height: 75px !important;

        &.co-branded {
            justify-content: space-between;
        }

        img {
            width: 150px !important;
            height: 75px !important;
            max-block-size: 75px !important;
        }
    }
}