@import "@assets/variables";

.nav-bar {
    .nav-bar-bottom {
        flex: 0 0 8vh;
        height: 8vh;
        background-color: #FFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 996;
        box-shadow: 0 3px 7px rgba(0,0,0,0.2);
        -webkit-transition: 200ms;
        transition: 200ms;
        pointer-events: auto;

        .btn-menu {
            font-weight: 500;
            user-select: none;
            opacity: 0;
            cursor: pointer;
            display: inline-flex;
            align-items: center;

            i {
                margin-right: 10px;
                font-size: 1rem;
            }
        }
    }

    &.transparent {
        .nav-bar-bottom {
            background-color: rgba(255,255,255,0.3);

            .btn-menu {
                color: #FFF;
            }
        }
    }
}

@media screen and (max-width: $tabletWidth) {
    .nav-bar {
        .nav-bar-bottom {
            z-index: 997;
            -webkit-transition: 200ms;
            transition: 200ms;
            transition-delay: 100ms;
            background-color: #F2F6F6;

            .btn-menu {
                opacity: 1;
                transition: 200ms;
                transition-delay: 100ms;
            }
        }

        &.transparent {
            .nav-bar-bottom {
                background-color: #F2F6F6;
    
                .btn-menu {
                    color: initial;
                }
            }
        }

        &.menu-open {
            .nav-bar-bottom {
                transition-delay: 0ms;
                box-shadow: none;
                background-color: #F2F6F6;

                .btn-menu {
                    color: #534D67;
                    transition-delay: 0ms;
                }
            }
        }
    }
}

.btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: relative !important;
    border: none !important;
    border-radius: 8px !important;
    padding: 1.7vh !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
    font-size: 1.5vh !important;
    cursor: pointer !important;

    h6 {
        display: block !important;
        margin: 0px !important;
    }
}