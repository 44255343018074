@import "@assets/variables";

.slider-thumbs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 1vh 0.5vw;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    z-index: 2;
    width: 100%;

    i {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        flex: 0 1 4vh;
        height: 45px;
        width: 45px;
        min-width: 45px;
        font-size: clamp(16px, 2.2vh, 22px);
        cursor: pointer;
        transition: 200ms;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }

    .thumbs-container {
        display: flex;
        gap: 8px;
        flex: 0 1 auto;
        max-width: 472px;
        overflow: hidden;
        align-items: center;
        height: 9px;
        min-width: 88px;

        .slider-thumb {
            flex: 0 0 8px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.2);
            cursor: pointer;
            -webkit-transition: 100ms;
            transition: 100ms;
            will-change: background-color, transform;

            &.active {
                background-color: #534D67;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

}

@media screen and (max-width: $tabletWidth) {
    .slider-thumbs {
        justify-content: center;
        width: 100%;
        gap: 2vw;
        padding: 2vh 4vw;
        position: relative;
        top: unset;
        right: unset;
        transform: unset;

        .slider-thumb {
            border: 1px solid var(--secondary-color);
            width: 6px;
            height: 6px;
            background-color: #FFF;
        }
    }
}