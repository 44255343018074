.slide-up-button {
    position: relative;
    color: #5E5873;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin: 0 4.5vw;
    padding: 0;
    border-bottom: 1px solid rgba(94, 88, 115, 0.2); 

    h5 {
        padding: 0.9375rem 0 !important;
    }

    i {
        font-size: 1.5rem;
        color: #534D67;
    }
}

.slide-up-parent {
    position: fixed;
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    display: none;
    transform: translateY(0);
    overflow: hidden;
    background-color: transparent;
    z-index: 1000;

    &.open {
        display: block;
        transform: translateY(-100%);
        transition: transform 200ms;
        height: 100%;
    }
}

.slide-up-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFF;
    overflow: auto;

    &.is-open {
        animation: slide-up 300ms ease;
    }

    &.is-closed {
        animation: slide-down 300ms ease;
    }

    @keyframes slide-up {
        0% {
            transform: translateY(100%);
        }

        100% {
            transform: translateY(0);
        }
    }

    @keyframes slide-down {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(100%);
        }
    }

    .slide-up-header {
        display: flex;
        align-items: center;
        background-color: rgba(242, 246, 246, 1);
        padding: 20px;
        text-align: left;

        i {
            margin-right: 15px;
            font-size: 1.5rem;
        }
    }

    .slide-up-body {
        overflow: auto;
        height: 100%;
    }
}
