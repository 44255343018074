@import "@assets/variables";

.cover-page {
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: #FFF;

    .swiper {
        height: 100%;
    }

    h1 {
        padding: 0 5vw;
    }

    img {
        width: 100%;
        height: 100%;

        max-inline-size: 100%;
        block-size: 100%;
        object-fit: cover;
    }

    .text {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.4);
        color: #FFF;

        h1 {
            margin: 1.875rem 0;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            list-style-type: none;
            max-width: 50vw;
            margin-top: 0.9375rem;
            padding: 0;

            li {
                margin-right: 10px;

                h4 {
                    margin: 0;
                    font-size: clamp(1.1rem, 2.5vw, 1.56rem);
                }

                i {
                    margin: 0 10px 0 0;
                }
            }
        }
    }
    
    .icon-arrow-down {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        color: #FFF;
        font-size: 20px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255,255,255,0.2);
        border-radius: 50%;

        $container-height: 200px;
        $iterations: 10;
        $gap: 10px;

        @media screen and (max-width: 768px) {
            $container-height: 150px;
            $iterations: 8;
            $gap: 8px;
        }
            
        &::after {
            content: "";
            position: absolute;
            bottom: 80%;
            left: 50%;
            transform: translateX(-50%);
            width: 2px;
            height: $container-height;

            @function fadingLines() {
                $line-size: calc(($container-height - ($iterations * $gap)) / $iterations);
                $result: unquote("");

                @for $i from 0 through $iterations {
                    $start: ($i * $line-size) + ($i * $gap);
                    $end: $start + $line-size;
                    $opacity: calc(1 - ($i * (1 / $iterations)));

                    $result: $result + 
                        rgba(255, 255, 255, $opacity) $start,
                        rgba(255, 255, 255, $opacity) $end,
                        transparent $end,
                        transparent $end + $gap;

                        @if ($i != $iterations) {
                            $result: $result + unquote(",");
                        }
                }
                @return 0deg, $result;
            }

            background: linear-gradient(fadingLines());
        }
    }
}

.disabled-section {
    margin-top: 5vh;
}

.icon-warning-icon {
    margin-right: 2vh;
}

@media screen and (max-width: $mobileWidth) {
    .cover-page {
        img {
            object-fit: cover;
        }

        .swiper-container {
            height: 100% !important;
        }
    }
}

@media screen and (max-width: $tabletWidth) {
    .cover-page {
        .text {
            padding: 0 6.4vw;

            h4 {
                margin: 3vh 0;
            }

            ul {
                max-width: 93.6vw;
            }
        }

        .icon-arrow-down {
            bottom: 50px;
        }
    }
}