.video-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;

    iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: calc((100vw * 9) / 16);
        transform: translate(-50%, -50%);
    }
}

@media (max-aspect-ratio: 16 / 9) {
    .video-container {
        iframe {
            width: calc((100vh * 16) / 9);
            height: 100vh;
        }
    }
}