.block-container-toolbar {
  display: none;
}

.block-container:hover {
  padding: 4px;
  border: 2px solid #6CB741;
  cursor: pointer;

  .block-container-toolbar {
    display: block;
  }
}

.floating-elements {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 10vh;
  right: 1vw !important;
  z-index: 999;

  .floating-contact {
    bottom: 3vh;
  }
}

.modal-close {
  background: none;
  border: none;
}
