@import '@assets/variables';

.nav-bar {
    width: 100%;
    position: fixed;
    z-index: 998;
    height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    -webkit-transition: 200ms;
    transition: 200ms;
    transform: translateY(0);
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    &.hidden:not(.unbranded) {
        transform: translateY(calc(-8vh - 1px));

        &.small {
            transform: translateY(-75px);
        }
    
        &.medium {
            transform: translateY(-100px);
        }
    
        &.large {
            transform: translateY(-150px);
        }
    }
}

@media screen and (max-width: $desktopWidth) {
    .nav-bar {
        &.hidden:not(.unbranded) {
            &.small, &.medium, &.large  {
                transform: translateY(-76px) !important;
            }
        }
    }
}