.container {
    margin-top: 30vh;
    text-align: center;

    img {
        height: 10vw;
        width: 10vw;
    }
}

label {
    font-size: 1rem;
    color: #6F7072;
}

.header-text {
    font-weight: 700;
    margin-bottom: 1vh;
}

